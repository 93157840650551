//
//
// rikupuu.fi website
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// ALL RIGHTS RESERVED
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


//
// webpack entry file
//


import '../css/styles.scss'

import 'FRONTEND/images/favicon.ico'


document.addEventListener('DOMContentLoaded', () => {

  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)

  $navbarBurgers.forEach( el => {
    el.addEventListener('click', () => {
      const target = el.dataset.target
      const $target = document.getElementById(target)

      el.classList.toggle('is-active')
      $target.classList.toggle('is-active')
    })
  })

})
